import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { constraintConfig } from '../../constraintConfig'
import { selectAuth } from '../../features/authSlice'
import { useAppSelector } from '../../hooks'
import BudgetHistory from '../pages/budget/BudgetHistory'
import BudgetPage from '../pages/budget/BudgetPage'
import ClientWrapper from '../pages/clientAndProject/client/ClientWrapper'
import ProjectsWrapper from '../pages/clientAndProject/projects/ProjectsWrapper'
import Categories from '../pages/configCategories/categories/Categories'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import DraftItemsPage from '../pages/expenseItems/DraftItemsPage'
import ExpenseForAp from '../pages/expenseItems/expenseForAp/ExpenseForAp'
import ExpenseItemDetail from '../pages/expenseItems/ExpenseItemDetail'
import ExpenseItemWrapper from '../pages/expenseItems/ExpenseItemWrapper'
import ExpenseSubmissionWrapper from '../pages/expenseItems/ExpenseSubmissionWrapper'
import GoogleMapComponent from '../pages/expenseItems/GoogleMap'
import TeamMemberExpenses from '../pages/expenseItems/TeamMemberExpenses'
import InvoicesWrapper from '../pages/Invoices/InvoicesWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import MileageWrapper from '../pages/mileage/MileageWrapper'
import OrganizationPageWrapper from '../pages/organization/OrganizationPageWrapper'
import OrgSettings from '../pages/orgSettings/OrgSettings'
import Report from '../pages/reportAnalytics/Report'
import ReportAnalyticsWrapper from '../pages/reportAnalytics/ReportAnalyticsWrapper'
import ReporteesPage from '../pages/reportees/ReporteesPage'
import RolesPageWrapper from '../pages/roles/RolesPageWrapper'
import AutomationCCFeedsStatsEnhancements from '../pages/settings/automation/AutomationCCFeedsStatsEnhancements'
import BankCCFeeds from '../pages/settings/automation/BankCCFeeds'
import CategoryBudgetWrapper from '../pages/settings/categoryBudgeting/CategoryBudgetWrapper'
import CountriesWrapper from '../pages/settings/countries/CountriesWrapper'
import DepartmentWrapper from '../pages/settings/department/DepartmentWrapper'
import DivisionWrapper from '../pages/settings/division/DivisionWrapper'
import ExpenseVoilationWrapper from '../pages/settings/expenseVoilation/ExpenseVoilationWrapper'
import HelpSupportPage from '../pages/settings/helpCenter/HelpSupportPage'
import LocationWrapper from '../pages/settings/locations/LocationWrapper'
import UsersPageWrapper from '../pages/users/UsersPageWrapper'
import CardWrapper from '../pages/vendorAndCard/card/CardWrapper'
import VendorWrapper from '../pages/vendorAndCard/vendor/VendorWrapper'
import ApproversPage from '../pages/workFlow/ApproversPage'
import SingleApproverPage from '../pages/workFlow/SingleApproverPage'
import WorkFlowPage from '../pages/workFlow/WorkFlowPage'
import QueryReportPage from '../pages/reportAnalytics/queryReport/QueryReportPage'
import RequestLogs from '../pages/settings/logs/RequestLogs'
import { allRoutes } from './All_Routes'
import BackUpApprovers from '../pages/expenseItems/backupApprovers/BackUpApprovers'
import UserCCFeeds from '../pages/settings/automation/UserCCFeeds'
import MergeLineItems from '../pages/settings/mergeLineItems/MergeLineItems'

// import SingleUserDetailPage from '../pages/users/SingleUserDetailPage'

const PrivateRoutes = () => {
  const { roleID } = useAppSelector(selectAuth);

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserProfilePage = lazy(() => import('../modules/profile/UserProfilePage'))

  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const MyReportPage = lazy(() => import('../pages/reportAnalytics/myReport/MyReportPage'))
  const TeamReportPage = lazy(() => import('../pages/reportAnalytics/teamReport/TeamReportPage'))
  const OrgSettingss = lazy(() => import('../pages/orgSettings/OrgSettings'))



  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {roleID === constraintConfig.roleID.role10 ?
          <Route path='auth/*' element={<Navigate to='/ap-approved' />} /> :
          roleID === constraintConfig.roleID.roleIDPortalSupport ?
            <Route path='auth/*' element={<Navigate to='/portal-support-requests' />} />
            : <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        }
        {/* <Route path='set-password/:token' element={<SetPassword />} /> */}
        {roleID !== constraintConfig.roleID.role10 && roleID !== constraintConfig.roleID.roleIDPortalSupport &&
          <Route path='dashboard' element={<DashboardWrapper />} />}

        {(roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.role5 || roleID === constraintConfig.roleID.role2) && (
          <>
            <Route path={allRoutes.expenseWizard} element={<ExpenseSubmissionWrapper />} />
            <Route path={allRoutes.expenseReports} element={<ExpenseItemWrapper />} />
            <Route path={`${allRoutes.expenseReports}/pending`} element={<ExpenseItemWrapper />} />
            <Route path={`${allRoutes.expenseReports}/approved`} element={<ExpenseItemWrapper />} />
            <Route path={`${allRoutes.expenseReports}/apApproved`} element={<ExpenseItemWrapper />} />
            <Route path='expense-line-items' element={<DraftItemsPage />} />
            <Route path='card' element={<CardWrapper />} />
            <Route path='report/:id' element={<Report />} />
            <Route path='receipt-vault' element={<InvoicesWrapper />} />
          </>
        )}
        {(roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.role4) && (
          <>
            <Route path='approval-queue' element={<TeamMemberExpenses />} />
            <Route path='approval-queue/all' element={<TeamMemberExpenses />} />
            <Route path='approval-queue/pending' element={<TeamMemberExpenses />} />
          </>
        )}
        {(roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.role4) && (
          <>
            <Route path='budget-allocation/budget-history' element={<BudgetHistory />} />
            <Route path='budget-allocation/budget' element={<BudgetPage />} />
            <Route path='budget-allocation/category-budgeting' element={<CategoryBudgetWrapper />} />

          </>
        )}
        {/* {(roleID === constraintConfig.roleID.role5) && (
          <> */}
        <Route path='/budget-history' element={<BudgetHistory />} />
        {/* </>
        )} */}
        <Route path='team-management' element={<ReporteesPage />} />
        <Route path='budget-allocation/budgets' element={<BudgetPage />} />
        {(roleID === constraintConfig.roleID.role4) && (
          <>
            <Route path='active/team-management' element={<ReporteesPage />} />
            <Route path='all/team-management' element={<ReporteesPage />} />
            {/* <Route path={allRoutes.backupApprovers} element={<BackUpApprovers />} /> */}
          </>
        )}

        {roleID !== constraintConfig.roleID.role10 && roleID !== constraintConfig.roleID.role1 &&
          <Route
            path={`${allRoutes.analytics}/my-report/*`}
            element={
              <SuspensedView>
                <MyReportPage />
              </SuspensedView>
            }
          />
        }
        {roleID === constraintConfig.roleID.role4 ?
          <Route
            path={`${allRoutes.analytics}/team-report/*`}
            element={
              <SuspensedView>
                <TeamReportPage />
              </SuspensedView>
            }
          /> : null}
        {(roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.role10 || roleID === constraintConfig.roleID.role2) && (
          <Route
            path={`${allRoutes.analytics}/org-report/*`}
            element={
              <SuspensedView>
                <QueryReportPage />
              </SuspensedView>
            }
          />
        )}

        {(roleID === constraintConfig.roleID.role1) && (
          <>
            <Route path='organization' element={<OrganizationPageWrapper />} />
            <Route path='organization/active' element={<OrganizationPageWrapper />} />
            <Route path='organization/new' element={<OrganizationPageWrapper />} />
            <Route path='organization/inprogress' element={<OrganizationPageWrapper />} />
            <Route path='roles' element={<RolesPageWrapper />} />
          </>

        )}
        {(roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) && (
          <>
            <Route path='expense-users' element={<UsersPageWrapper />} />
            <Route path='all/expense-users' element={<UsersPageWrapper />} />
            <Route path='active/expense-users' element={<UsersPageWrapper />} />
            <Route path='expense-users/managers' element={<UsersPageWrapper />} />
            <Route path='active/managers' element={<UsersPageWrapper />} />
          </>
        )}
        {(roleID === constraintConfig.roleID.role2) && (
          <>
            <Route path='settings/org-settings' element={<OrgSettings />} />
            <Route path='settings/vendor' element={<VendorWrapper />} />
            <Route path='settings/workflow' element={<WorkFlowPage />} />
            <Route path='settings/mileage' element={<MileageWrapper />} />
            <Route path='settings/categories' element={<Categories />} />
            <Route path='settings/departments' element={<DepartmentWrapper />} />
            <Route path='settings/countries' element={<CountriesWrapper />} />
            <Route path='settings/division' element={<DivisionWrapper />} />
            {/* <Route path='settings/expense-type-code' element={<ExpenseTypeCodeWrapper />} /> */}
            <Route path='settings/expense-violation' element={<ExpenseVoilationWrapper />} />
            <Route path='settings/locations' element={<LocationWrapper />} />
            <Route path='settings/automation/bank-cc-feeds' element={<BankCCFeeds />} />
            <Route path='settings/automation/bank-cc-detail/:id' element={<AutomationCCFeedsStatsEnhancements />} />
            <Route path='settings/automation/user-cc-feeds' element={<UserCCFeeds />} />
            <Route path='settings/automation/merge-line-items' element={<MergeLineItems />} />
            <Route path='settings/automation/automation-cc-feeds-stats-enhancements' element={<AutomationCCFeedsStatsEnhancements />} />
            <Route path='settings/request-logs' element={<RequestLogs />} />
            <Route path='settings/backup-approvers' element={<BackUpApprovers />} />


            {/* <Route path='department-location' element={<DepartmentLocationWrapper />} /> */}
            {/* <Route path='active-expense-violation' element={<ActiveExpenseViolationWrapper />} /> */}

            <Route
              path='settings/org-settings/*'
              element={
                <SuspensedView>
                  <OrgSettingss />
                </SuspensedView>
              }
            />
          </>


        )}
        {(roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.roleIDPortalSupport) && (
          <Route path='portal-support-requests' element={<HelpSupportPage />} />
        )}
        {/* {(roleID === constraintConfig.roleID.role10) && ( */}
        <Route path='ap-approved' element={<ExpenseForAp />} />
        {/* )} */}
        <Route path='approvers' element={<ApproversPage />} />
        <Route path='approvers/:expenseId/:dateTime' element={<SingleApproverPage />} />
        <Route path='expense-item-detail/:expenseId/:dateTime' element={<ExpenseItemDetail />} />
        <Route path='clients' element={<ClientWrapper />} />
        <Route path='projects' element={<ProjectsWrapper />} />
        {/* <Route path='budget-allocation/category-budgeting' element={<CategoryBudgetWrapper />} /> */}
        <Route path='map' element={<GoogleMapComponent />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {/* <Route path='singleUserDetail/:id' element={<SingleUserDetailPage />} /> */}


        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-profile/*'
          element={
            <SuspensedView>
              <UserProfilePage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='workflow/*'
          element={
            <SuspensedView>
              <WorkFlowPageWrapper />
            </SuspensedView>
          }
        /> */}

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

