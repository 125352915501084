import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { constraintConfig } from '../../../../constraintConfig'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { useUploadOCRInvoiceMutation } from '../../../../services/BackgroundJobApi'
import { useAddUpdateCardExpenseItemMutation } from '../../../../services/ExpenseApi'
import { useUploadFileToAzureMutation } from '../../../../services/IntegrartionApi'
import { useGetSingleOrganizationQuery } from '../../../../services/OrganizationApi'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import Step2 from './steps/Step2'
import { Step5 } from './steps/Step5'
import { boolean } from 'yup'

type Props = {
  show: boolean
  handleClose: () => void
  viewOnlyData?: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddExpenseItemModal = ({ show, handleClose, viewOnlyData }: Props) => {
  const { userAccountID, userInOrgId, location, locationName } = useAppSelector(selectAuth)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const [uploadFileToAzureForMilage] = useUploadFileToAzureMutation();
  const [uploadFileToAzure, { data: azurefileData, isLoading: fileIsloading, error: fileError, isError: fileIsError }] = useUploadOCRInvoiceMutation();

  const [addUpdateCardExpenseItem, { isLoading, isError, error, isSuccess }] = useAddUpdateCardExpenseItemMutation();
  const { data: orgDetail, refetch: orgDetailRefetch } = useGetSingleOrganizationQuery(userInOrgId)
  // const { data: userAccountDetail, refetch: userDetailRefetch } = useGetUserAccountDetailQuery(userAccountID)

  useEffect(() => {
    if (show) {
      orgDetailRefetch()
    }
  }, [show, orgDetailRefetch])

  const currentDate = new Date();

  const initialValues = {
    expenseItemId: 0,
    expenseId: 0,
    userAccountId: userAccountID,
    expenseCategory: {
      id: 1,
      title: 'Other'
    },
    itemDetail: '',
    vendor: {
      vendorId: 0,
      name: '',
      disc: '',
      address: '',
      active: true,
      email: '',
      phone: ''
    },
    paymentMethod: {
      id: 1,
      title: ''
    },
    currency: {
      id: 2,
      title: ''
    },
    tripType: {
      id: 1,
      title: ''
    },
    expItemLocation: {
      id: location || 0,
      title: locationName || ''
    },
    isKM: true,
    isMile: false,
    milage: 0,
    amount: 0,
    attachmentFile: '',
    file: '',
    itemDate: currentDate.toISOString().split('T')[0],
    startLocation: '',
    endLocation: '',
    invoiceDataDraftId: 0,
    reimbursableDistance: 0,
    startLatLngs: {
      lat: '',
      lng: ''
    },
    endLatLngs: {
      lat: '',
      lng: ''
    },
    mileageRate: '',
    isSplitLineItem: false,
    splitLineItems: [
      {
        splitLineItemId: 0,
        expItemLocation: {
          id: 0,
          title: ''
        },
        amount: ''
      },
      {
        splitLineItemId: 0,
        expItemLocation: {
          id: 0,
          title: ''
        },
        amount: '',
        percentage: 0
      }
    ]
  }
  const [imagePreviewFirst, setImagePreviewFirst] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false)


  const handleImageChangeFirst = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsPdf(file.type === 'application/pdf');
      const reader = new FileReader();
      reader.onload = async () => {
        setImagePreviewFirst(reader.result as string);
        const formData = new FormData();
        formData.append('file', file);
        const fileName = file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name;

        try {
          let response;
          if (formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage) {
            response = await uploadFileToAzureForMilage(formData);
          } else {
            response = await uploadFileToAzure(formData);
          }

          const successMessage = (response as { data?: { message?: string } })?.data?.message;
          if (successMessage) {
            toast.success(`${successMessage}`);
          }

          if (formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage) {
            const uploadedFileUrl = (response as { data?: any })?.data?.result;
            formik.setFieldValue('attachmentFile', uploadedFileUrl);
            formik.setFieldValue('itemDate', currentDate.toISOString().split('T')[0]);
            formik.setFieldValue('amount', 0);
            formik.setFieldValue('vendor.vendorId', 0);
            formik.setFieldValue('vendor.name', '');
            formik.setFieldValue('invoiceDataDraftId', 0);
          } else {
            const uploadedFileUrl = (response as { data?: any })?.data?.result?.fileURL;
            const invoiceDate = (response as { data?: any })?.data?.result?.invoiceDate;
            const amount = (response as { data?: any })?.data?.result?.amount;
            const vendorId = (response as { data?: any })?.data?.result?.vendorInfo?.vendorId;
            const vendorName = (response as { data?: any })?.data?.result?.vendorInfo?.name;
            const catagoryId = (response as { data?: any })?.data?.result?.catagoryId;
            const catagoryName = (response as { data?: any })?.data?.result?.catagoryName;
            const paymentMethodId = (response as { data?: any })?.data?.result?.paymentMethodId;
            const paymentMethodName = (response as { data?: any })?.data?.result?.paymentMethodName;
            const invoiceDataDraftId = (response as { data?: any })?.data?.result?.invoiceDataDraftId;

            // Set the file name in the state
            setFileName(fileName);
            formik.setFieldValue('attachmentFile', uploadedFileUrl);
            formik.setFieldValue('itemDate', invoiceDate);
            formik.setFieldValue('amount', amount);
            formik.setFieldValue('vendor.vendorId', vendorId);
            formik.setFieldValue('vendor.name', vendorName);
            formik.setFieldValue('expenseCategory.id', catagoryId);
            formik.setFieldValue('expenseCategory.title', catagoryName);
            formik.setFieldValue('paymentMethod.id', paymentMethodId);
            formik.setFieldValue('paymentMethod.title', paymentMethodName);
            formik.setFieldValue('invoiceDataDraftId', invoiceDataDraftId);
          }
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const [fileRemoved, setFileRemoved] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleRemoveFile = () => {
    // Reset the uploaded file and related states
    formik.setFieldValue('attachmentFile', null);
    setFileRemoved(true);

    // Reset the file input value
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.value = ''; // Clear the input value
    }
  };
  useEffect(() => {
    if (fileIsError && fileError) {
      let errorMessage: string = 'Something went wrong';
      if ('data' in fileError && fileError.data && typeof fileError.data === 'object' && 'message' in fileError.data) {
        errorMessage = (fileError.data as { message: string }).message;
      }
      toast.error(errorMessage);
      formik.setFieldValue('attachmentFile', null);
      setFileRemoved(true);
      // Clear the file input
      const fileInput = fileInputRef.current;
      if (fileInput) {
        fileInput.value = ''; // Clear the input value
      }
    }
  }, [fileIsError, fileError]);

  useEffect(() => {
    // Check if the file was removed and update UI accordingly
    if (fileRemoved) {
      // Reset file-related states
      setFileName(null);
      setImagePreviewFirst(null);
      // Reset file removed state
      setFileRemoved(false);
    }
  }, [fileRemoved]);
  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // Validation checks
      const selectedDate = new Date(formik.values?.itemDate);
      const totalSplitAmount = values.splitLineItems.reduce((total, item) => total + Number(item.amount), 0);


      if (!values.expenseCategory.id) {
        toast.error(tosterKey.categoryIsRequired);
        return;
      } else if (values.expenseCategory.id !== constraintConfig.categoriesId.mileage && orgDetail?.result?.receiptReqMinAmnt < values.amount && !values.attachmentFile) {
        toast.error(tosterKey.recieptIsRequired);
        return;
      } else if (!values.itemDate) {
        toast.error(tosterKey.dateIsRequired);
        return;
      } else if (selectedDate > currentDate) {
        toast.error(tosterKey.dateCannotBeInFuture);
        return;
      } else if (!values.currency.id) {
        toast.error(tosterKey.currencyIsRequired);
        return;
      } else if (values.expenseCategory.id.toString() !== "14" && !values.amount) {
        toast.error(tosterKey.amountIsRequired);
        return;
      } else if (formik.values?.expenseCategory?.id?.toString() !== "14" && orgDetail?.result?.expPolicyMaxLimit < formik?.values?.amount) {
        toast.error(tosterKey.AmountExceedAccordingToExpensePolicyLimit);
        return;
      } else if (values.expenseCategory.id.toString() === "14" && !values.tripType?.id) {
        toast.error(tosterKey.tripTypeIsRequired);
        return;
      }
      else if (values.expenseCategory.id.toString() === "14" && !values.startLocation) {
        toast.error(tosterKey.startLocationIsRequired);
        return;
      }
      else if (values.expenseCategory.id.toString() === "14" && !values.endLocation) {
        toast.error(tosterKey.endLocationIsRequired);
        return;
      }
      else if (values.expenseCategory.id.toString() === "14" && !values.milage) {
        toast.error(tosterKey.startAndEndLocationIsRequired);
        return;
      } else if (values.expenseCategory.id.toString() !== "14" && !values.vendor.vendorId) {
        toast.error(tosterKey.vendorIsRequired);
        return;
      } else if (values.expenseCategory.id.toString() !== "14" && !values.expItemLocation.id) {
        toast.error(tosterKey.locationIsRequired);
        return;
      } else if (!values.paymentMethod.id) {
        toast.error(tosterKey.paymentMethodIsRequired);
        return;
      } else if (values.itemDetail.trim().length > 500) {
        toast.error(tosterKey.expenseItemDetailMustNotExceed500Characters);
        return;
      }

      // // Split Line Items Validation
      // if (values.isSplitLineItem) {
      //   for (let i = 0; i < values.splitLineItems.length; i++) {
      //     const splitItem = values.splitLineItems[i];
      //     if (!splitItem.amount || Number(splitItem.amount) <= 0) {
      //       toast.error(`Amount is required and must be greater than 0 for split line item ${i + 1}`);
      //       return;
      //     }
      //     if (!splitItem.expItemLocation?.id) {
      //       toast.error(`Location is required for split line item ${i + 1}`);
      //       return;
      //     }
      //   }

      //   // Check if split amount matches the total amount
      //   if (totalSplitAmount !== Number(values.amount)) {
      //     toast.error(tosterKey.splitAmountMustMatchTotalAmount);
      //     return;
      //   }
      // } else {
      //   // If split line items are present but `isSplitLineItem` is false
      //   if (values.splitLineItems.length > 0) {
      //     for (let i = 0; i < values.splitLineItems.length; i++) {
      //       const splitItem = values.splitLineItems[i];
      //       if (splitItem.amount && (!splitItem.expItemLocation?.id)) {
      //         toast.error(`Location is required for split line item ${i + 1}`);
      //         return;
      //       }
      //     }
      //   }
      // }

      // New validation to check incomplete split line items even when isSplitLineItem is false
      if (values.splitLineItems.length > 0) {
        for (let i = 0; i < values.splitLineItems.length; i++) {
          const splitItem = values.splitLineItems[i];

          if (splitItem.amount && !splitItem.expItemLocation?.id && values.isSplitLineItem) {
            toast.error(`Location is required for split line item ${i + 1}`);
            return;
          }
        }
      }

      // Validate required fields within splitLineItem when isSplitLineItem is true
      if (values.isSplitLineItem) {
        for (let i = 0; i < values.splitLineItems.length; i++) {
          const splitItem = values.splitLineItems[i];

          if (!splitItem.expItemLocation?.id) {
            toast.error(`Location is required for split line item ${i + 1}`);
            return;
          }
          if (!splitItem.amount || Number(splitItem.amount) <= 0) {
            toast.error(`Amount is required and must be greater than 0 for split line item ${i + 1}`);
            return;
          }
        }

        // Check if split amount matches the total amount
        const totalSplitAmount = values.splitLineItems.reduce((sum, lineItem) => sum + (parseFloat(lineItem.amount) || 0), 0);
        const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;
        const totalAmount = Number(values.amount);
        const remainingAmount = totalAmount - roundedTotalSplitAmount;
        const roundedRemainingAmount = Math.round(remainingAmount * 100) / 100;

        if (roundedRemainingAmount !== 0) {
          toast.error(`${tosterKey.splitAmountMustMatchTotalAmount}`);
          return;
        }
      }


      const formData = new FormData();
      formData.append('expenseItemId', String(values.expenseItemId));
      formData.append('userAccountId', String(values.userAccountId));
      formData.append('expenseCategory.id', String(values.expenseCategory.id));
      formData.append('itemDetail', values.itemDetail);
      formData.append(`vendor.vendorId`, String(values.vendor.vendorId));
      formData.append('paymentMethod.id', String(values.paymentMethod.id));
      formData.append('currency.id', String(values.currency.id));
      formData.append('tripType.id', String(values.tripType.id));
      formData.append('expItemLocation.id', String(values.expItemLocation.id));
      formData.append('isKM', String(values.isKM));
      formData.append('isMile', String(values.isMile));
      formData.append('amount', String(values.amount));
      formData.append('milage', String(values.milage));
      formData.append('itemDate', values.itemDate);
      formData.append(`attachmentFile`, values.attachmentFile);
      formData.append(`startLocation`, values.startLocation);
      formData.append(`endLocation`, values.endLocation);
      formData.append(`invoiceDataDraftId`, String(values.invoiceDataDraftId));
      formData.append(`startLatLngs.lat`, values.startLatLngs.lat);
      formData.append(`startLatLngs.lng`, values.startLatLngs.lng);
      formData.append(`endLatLngs.lat`, values.endLatLngs.lat);
      formData.append(`endLatLngs.lng`, values.endLatLngs.lng);
      formData.append(`mileageRate`, values.mileageRate);
      formData.append('isSplitLineItem', String(values.isSplitLineItem));



      // Append splitLineItems
      values.splitLineItems.forEach((item, index) => {
        formData.append(`splitLineItems[${index}].splitLineItemId`, String(item.splitLineItemId));
        formData.append(`splitLineItems[${index}].expItemLocation.id`, String(item.expItemLocation.id));
        formData.append(`splitLineItems[${index}].amount`, String(item.amount));
        formData.append(`splitLineItems[${index}].percentage`, item.percentage ? String(item.percentage) : '');
      });

      addUpdateCardExpenseItem(formData)
    }
  })

  const { resetForm } = formik;
  useEffect(() => {
    if (fileName !== null && imagePreviewFirst !== null) {
      setFileName(null);
      setImagePreviewFirst(null);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(tosterKey.itemCreatedSuccessfully);
      resetForm()
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);


  useEffect(() => {
    if (show) {
      setImagePreviewFirst(null);
      setFileName(null);
    }
  }, [show]);

  const handleModalClose = () => {
    resetForm();
    setImagePreviewFirst(null); // Clear image preview
    setFileName(null); // Clear file name
    handleClose();
  };
  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
      show={show}
      onHide={handleModalClose}
      onEntered={loadStepper}
      backdrop="static"
    >
      <div className='modal-header'>
        <h2>{labelKey.addExpenseItem}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.expenseItem}</h3>

                    <div className='stepper-desc'>{labelKey.addExpenseItem}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {/* <div className='stepper-line h-40px'></div> */}
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}



              {/* begin::Step 5*/}
              {/* <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.overview}</h3>
                    <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                  </div>
                </div>
              </div> */}
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' onSubmit={formik.handleSubmit} className='position-relative'>
              <Step2 formik={formik}
                handleImageChangeFirst={handleImageChangeFirst}
                imagePreviewFirst={imagePreviewFirst}
                fileName={fileName}
                fileIsloading={fileIsloading}
                handleRemoveFile={handleRemoveFile}
                fileInputRef={fileInputRef}
                orgDetail={orgDetail}
                isPdf={isPdf}
                viewOnlyData={viewOnlyData}
              />
              <Step5 formik={formik} fileName={fileName} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                {/* <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    {labelKey.previous}
                  </button>
                </div> */}
                {/* <div> */}
                {azurefileData?.result?.remarks !== "bank lineitem" &&
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary ms-auto'
                    // data-kt-stepper-action='submit'
                    disabled={isLoading}
                  // onClick={submit}
                  >
                    {!isLoading && <span className='indicator-label'>{labelKey.save} <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    /></span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelKey.pleaseWait}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                }
                {/* <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    {labelKey.nextStep}{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button> */}
                {/* </div> */}
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { AddExpenseItemModal }

