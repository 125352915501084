import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { selectAuth } from '../features/authSlice';
import { RootState } from '../store';
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl.MAIN_BASE_URL,
});

type ExtraOptions = {
  headers?: Record<string, string>;
};

const CACHE_NAME = 'api-cache'; // Define a cache name
const CACHE_DURATION = 15 * 60 * 1000; // 1 minute in milliseconds

// Helper function to check if cached data is expired
const isCacheExpired = (timestamp: number) => {
  return Date.now() - timestamp > CACHE_DURATION;
};

// Helper function to clean up expired cache entries
const cleanUpExpiredCache = async () => {
//   console.log('Running cache cleanup...');
  const cache = await caches.open(CACHE_NAME);
  const keys = await cache.keys();

  for (const request of keys) {
    const cachedResponse = await cache.match(request);
    if (cachedResponse) {
      const cachedData = await cachedResponse.json();
      if (isCacheExpired(cachedData.timestamp)) {
        // console.log('Deleting expired cache for:', request.url);
        await cache.delete(request);
      }
    }
  }
};

// Schedule cache cleanup every 1 minute using setInterval
const scheduleCacheCleanup = () => {
  setInterval(cleanUpExpiredCache, CACHE_DURATION);
};

// Initialize cache cleanup scheduling when the module loads
scheduleCacheCleanup();

// Function to check if the request has query parameters
const hasQueryParams = (url: string, params?: Record<string, any>) => {
  // Check URL string for "?" OR if params object is present and not empty
  return url.includes('?') || (params && Object.keys(params).length > 0);
};

// Function to fetch data from Cache Storage (only for URLs without parameters)
const fetchFromCache = async (url: string, params?: Record<string, any>) => {
  if (hasQueryParams(url, params)) {
    // Skip caching for requests with query parameters
    return null;
  }

  const cache = await caches.open(CACHE_NAME);
  const cachedResponse = await cache.match(url);
  if (cachedResponse) {
    const cachedData = await cachedResponse.json();
    if (!isCacheExpired(cachedData.timestamp)) {
      return cachedData.data;
    }
    // Cache is expired, delete it
    await cache.delete(url);
  }
  return null;
};

// Function to save data to Cache Storage (only for URLs without parameters)
const saveToCache = async (url: string, data: any, params?: Record<string, any>) => {
  if (hasQueryParams(url, params)) {
    // Skip saving cache for requests with query parameters
    return;
  }

  const cache = await caches.open(CACHE_NAME);
  const response = new Response(
    JSON.stringify({ data, timestamp: Date.now() }),
    { headers: { 'Content-Type': 'application/json' } }
  );
  await cache.put(url, response);
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
  if (!orgBaseUrl) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'dynamic route not found',
      },
    };
  }
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = `${orgBaseUrl}/api/General${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
  const params = adjustedArgs.params; // Extract params if present
  const token = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')!).token
    : null;
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const mergedHeaders = { ...headers, ...extraOptions?.headers };

  // Check Cache Storage before making API request (only for requests without parameters)
  const cachedData = await fetchFromCache(adjustedUrl, params);
  if (cachedData) {
    return { data: cachedData }; // Return cached data if available and not expired
  }

  // Fetch from API if not in cache or cache expired
  const result = await rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions);

  // Save to Cache Storage after fetching (only for requests without parameters)
  if (result.data) {
    await saveToCache(adjustedUrl, result.data, params);
  }

  return result;
};

// Your createApi implementation...


export const GeneralApi = createApi({
    reducerPath: 'GeneralApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        familyMemberRealtionshipLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllFamilyRelationship,
                    method: "GET"
                }
            },
        }),
        familyMemberStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllFamilyMemberStatus,
                    method: "GET"
                }
            },
        }),
        expenseCategoryLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseCategoryLookup,
                    method: "GET"
                }
            },
        }),
        expenseSubCategoryLookup: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenseSubCategory}`,
                    method: "GET",
                    params
                }
            },
        }),
        allCurrencyLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCurrency,
                    method: "GET"
                }
            },
        }),
        expenseStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseStatus,
                    method: "GET"
                }
            },
        }),
        departmentsLookup: builder.query({


            query: () => {
                return {
                    url: API_END_POINTS.getAllDepartment,
                    method: "GET"
                }
            },
        }),
        designationLookup: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllDesignation}`,
                    method: "GET",
                    params
                }
            },
        }),
        ExpenseItemType: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllExpenseItemType}`,
                    method: "GET",
                    params
                }
            },
        }),
        workflowApproverStatus: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllWorkflowApproverStatus,
                    method: "GET"
                }
            },
        }),
        getManagerTeamLookup: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getManagerTeamLookup}`,
                    method: "GET",
                    params
                }
            },
        }),
        getPaymentMethodLookup: builder.query({
            query: (params) => {
                return {
                    url: API_END_POINTS.getAllExpensePaymentMethods,
                    method: "GET",
                    params
                }
            },
        }),
        getProjectStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllProjectStatus,
                    method: "GET"
                }
            },
        }),
        getAllClientLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllClientsLookup,
                    method: "GET"
                }
            },
        }),
        getAllCategory: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCategory,
                    method: "GET"
                }
            },
        }),
        updateAllCategory: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateAllCategory,
                    method: "PUT",
                    body
                }
            },
        }),
        getAllExpenseType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseType,
                    method: "GET",
                }
            },
        }),
        getAllDesignationWithoutRole: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllDesignationWithoutRole,
                    method: "GET",
                }
            },
        }),
        configCategoryUserLevel: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.configCategoryUserLevel,
                    method: "POST",
                    body
                }
            },
        }),
        getConfigCategoryWithUser: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getConfigCategoryByUser}`,
                    method: "GET",
                    params
                }
            },
        }),
        updateUserCategory: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateUserCategory,
                    method: "PUT",
                    body
                }
            },
        }),
        getAllNotificationPreferences: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllNotificationPreferences,
                    method: "GET",
                }
            },
        }),
        getAllDistancePreferences: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllDistancePreferences,
                    method: "GET",
                }
            },
        }),
        getApproversPolicyModeLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getDefaultApproversPolicyModeLookup,
                    method: "GET",
                }
            },
        }),
        getAllOrganizationLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllOganizationLookup,
                    method: "GET"
                }
            },
        }),
        getOnboardingStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getOnboardingStatusLookup,
                    method: "GET"
                }
            },
        }),
        // createExpenseCategory: builder.mutation({
        //     query: (body) => {
        //         return {
        //             url: API_END_POINTS.createExpenseCategory,
        //             method: "POST",
        //             body
        //         }
        //     },
        // }),
        // updateExpenseCategory: builder.mutation({
        //     query: (body) => {
        //         return {
        //             url: API_END_POINTS.updateExpenseCategory,
        //             method: "PUT",
        //             body
        //         }
        //     },
        // }),
        getAllLst_UserExpenseType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLst_UserExpenseType,
                    method: "GET"
                }
            },
        }),
        getAllLocations: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLocations,
                    method: "GET"
                }
            },
        }),
        getAllLocationType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLocationType,
                    method: "GET"
                }
            },
        }),
        getAllTripType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllTripType,
                    method: "GET"
                }
            },
        }),
        getDistanceByTripType: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getDistanceByTripType}`,
                    method: "GET",
                    params
                }
            },
        }),
        getAllCity: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllCity}`,
                    method: "GET",
                }
            },
        }),
        getAllCountry: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllCountries}`,
                    method: "GET",
                }
            },
        }),
        getAllDivision: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllDivisionLookup}`,
                    method: "GET",
                }
            },
        }),
        getViolationPolicyTypeLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getViolationPolicyTypeLookup}`,
                    method: "GET",
                }
            },
        }),
        getEmailSenderType: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getEmailSenderType}`,
                    method: "GET",
                }
            },
        }),
        getSupportUserLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getSupportUserLookup}`,
                    method: "GET",
                }
            },
        }),
        getSupportRequestStatusLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getSupportRequestStatusLookup}`,
                    method: "GET",
                }
            },
        }),
        getAppRequestSeverityLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAppRequestSeverityLookup}`,
                    method: "GET",
                }
            },
        }),
        getAppResolutionTypeLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAppResolutionTypeLookup}`,
                    method: "GET",
                }
            },
        }),
        getAllLookupsCacheList: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllLookupsCacheList}`,
                    method: "GET",
                }
            },
        }),
        clearAllLookupsCache: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.clearAlllLookupsCache,
                    method: "POST",
                    body
                }
            },
        }),
        getOcrReadStatusLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getOcrReadStatusLookup}`,
                    method: "GET",
                }
            },
        }),
        getAllDepartmentByManager: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllDepartmentByManager}`,
                    method: "GET",
                    params
                }
            },
        }),
        getUsersByManagerId: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getUsersByManagerId}`,
                    method: "GET",
                    params
                }
            },
        }),
        getAllManagersLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllManagersLookup}`,
                    method: "GET",
                }
            },
        }),
        getReportExpenseStatus: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getReportExpenseStatus}`,
                    method: "GET",
                }
            },
        }),
        getAllUsersLocation: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getAllUsersLocation}`,
                    method: "GET",
                    params
                }
            },
        }),
        getAllUsersLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllUsersLookup}`,
                    method: "GET",
                }
            },
        }),
        getAllTimeZone: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllTimeZone}`,
                    method: "GET",
                }
            },
        }),
        getAllUsersManagerAdmin: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllUsersManagerAdmin}`,
                    method: "GET",
                }
            },
        }),
        getAllCCFeedFile: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllCCFeedFile}`,
                    method: "GET",
                }
            },
        }),
        
    }),
})

export const {
    useFamilyMemberRealtionshipLookupQuery,
    useFamilyMemberStatusLookupQuery,
    useExpenseCategoryLookupQuery,
    useAllCurrencyLookupQuery,
    useExpenseStatusLookupQuery,
    useDepartmentsLookupQuery,
    useDesignationLookupQuery,
    useExpenseItemTypeQuery,
    useWorkflowApproverStatusQuery,
    useGetManagerTeamLookupQuery,
    useExpenseSubCategoryLookupQuery,
    useGetPaymentMethodLookupQuery,
    useGetProjectStatusLookupQuery,
    useGetAllClientLookupQuery,
    useGetAllCategoryQuery,
    useUpdateAllCategoryMutation,
    useGetAllExpenseTypeQuery,
    useGetAllDesignationWithoutRoleQuery,
    useConfigCategoryUserLevelMutation,
    useGetConfigCategoryWithUserQuery,
    useUpdateUserCategoryMutation,
    useGetAllNotificationPreferencesQuery,
    useGetAllDistancePreferencesQuery,
    useGetApproversPolicyModeLookupQuery,
    useGetAllOrganizationLookUpQuery,
    useGetOnboardingStatusLookupQuery,
    // useCreateExpenseCategoryMutation,
    // useUpdateExpenseCategoryMutation,
    useGetAllLst_UserExpenseTypeQuery,
    useGetAllLocationsQuery,
    useGetAllLocationTypeQuery,
    useGetAllTripTypeQuery,
    useGetDistanceByTripTypeQuery,
    useGetAllCityQuery,
    useGetAllCountryQuery,
    useGetAllDivisionQuery,
    useGetViolationPolicyTypeLookupQuery,
    useGetEmailSenderTypeQuery,
    useGetSupportUserLookupQuery,
    useGetSupportRequestStatusLookupQuery,
    useGetAppRequestSeverityLookupQuery,
    useGetAppResolutionTypeLookupQuery,
    useGetAllLookupsCacheListQuery,
    useClearAllLookupsCacheMutation,
    useGetOcrReadStatusLookupQuery,
    useGetAllDepartmentByManagerQuery,
    useGetUsersByManagerIdQuery,
    useGetAllManagersLookupQuery,
    useGetReportExpenseStatusQuery,
    useGetAllUsersLocationQuery,
    useGetAllUsersLookupQuery,
    useGetAllTimeZoneQuery,
    useGetAllUsersManagerAdminQuery,
    useGetAllCCFeedFileQuery
} = GeneralApi