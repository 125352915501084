import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StepperComponent } from '../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../_metronic/helpers';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useCreateExpenseWithItemMutation } from '../../../services/ExpenseApi';
import { useGetSingleOrganizationQuery } from '../../../services/OrganizationApi';
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import { Step1 } from './create-app-stepper/steps/Step1';
import { Step2 } from './create-app-stepper/steps/Step2';
import { Step5 } from './create-app-stepper/steps/Step5';
import { constraintConfig } from '../../../constraintConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { allRoutes } from '../../routing/All_Routes';


const ExpenseSubmission = () => {
    const { userAccountID, userInOrgId, expenseType, location, locationName } = useAppSelector(selectAuth);
    const uploadReceiptStep2 = useSelector((state: RootState) => state.uploadReceiptStep2);
    const remarksList = Object.values(uploadReceiptStep2).map(rowData => rowData.remarks);

    const navigate = useNavigate();
    const [isStep2Valid, setIsStep2Valid] = useState(false);
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }
    const [addExpense, { data: addExpenseData, isLoading, isError, error, isSuccess }] = useCreateExpenseWithItemMutation();
    // const { data: userAccountDetail, refetch: userDetailRefetch } = useGetUserAccountDetailQuery(userAccountID)
    const { data: orgDetail, refetch: orgDetailRefetch } = useGetSingleOrganizationQuery(userInOrgId)

    useEffect(() => {
        // userDetailRefetch()
        orgDetailRefetch()
    }, [orgDetailRefetch])

    const currentDate = new Date();
    const initialValues = {
        expenseId: 0,
        title: '',
        expenseType: {
            id: expenseType === 3 ? 1 : expenseType,
            title: expenseType === 1 ? 'Regular' : expenseType === 2 ? 'Flex' : 'Regular'
        },
        expenseDetail: '',
        reportDate: currentDate.toISOString().split('T')[0],
        expenseStatus: {
            id: 1,
            title: 'Draft'
        },
        userAccount: {
            orgUserID: userAccountID,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            imageUrl: ''
        },
        expenseItem: [
            {
                expenseItemId: 0,
                expenseId: 0,
                expenseCategory: {
                    id: 1,
                    title: 'Other'
                },
                tripType: {
                    id: 1,
                    title: ''
                },
                expItemLocation: {
                    id: location || 0,
                    title: locationName || ''
                },
                itemDetail: '',
                vendor: {
                    vendorId: 0,
                    name: '',
                    disc: '',
                    address: '',
                    active: true,
                    email: '',
                    phone: ''
                },
                isKM: false,
                isMile: false,
                paymentMethod: {
                    id: 1,
                    title: ''
                },
                currency: {
                    id: 2,
                    title: ''
                },
                amount: 0,
                milage: 0,
                attachmentFile: '',
                file: '',
                itemDate: currentDate.toISOString().split('T')[0],
                startLocation: '',
                endLocation: '',
                invoiceDataDraftId: 0,
                reimbursableDistance: 0,
                startLatLngs: {
                    lat: '',
                    lng: ''
                },
                endLatLngs: {
                    lat: '',
                    lng: ''
                },
                mileageRate: '',
                isSplitLineItem: false,
                splitLineItems: [
                    {
                        splitLineItemId: 0,
                        expItemLocation: {
                            id: 0,
                            title: ''
                        },
                        amount: ''
                    },
                    {
                        splitLineItemId: 0,
                        expItemLocation: {
                            id: 0,
                            title: ''
                        },
                        amount: '',
                        percentage: 0
                    }
                ]
            }
        ]
    }

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('expenseType.id', String(values.expenseType.id));
            formData.append('reportDate', values.reportDate);
            formData.append('expenseDetail', values.expenseDetail);
            formData.append('userAccount.orgUserID', String(values.userAccount.orgUserID));
            formData.append('expenseStatus.id', String(values.expenseStatus.id));
            values.expenseItem.forEach((item, index) => {
                const keyPrefix = `expenseItem[${index}].`;
                formData.append(`${keyPrefix}expenseCategory.id`, String(item.expenseCategory.id));
                // formData.append(`${keyPrefix}expenseSubCategory.id`, String(item.expenseSubCategory.id));
                formData.append(`${keyPrefix}itemDate`, item.itemDate);
                formData.append(`${keyPrefix}currency.id`, String(item.currency.id));
                formData.append(`${keyPrefix}amount`, String(item.amount));
                formData.append(`${keyPrefix}milage`, String(item.milage));
                formData.append(`${keyPrefix}vendor.vendorId`, String(item?.vendor?.vendorId));
                formData.append(`${keyPrefix}isKM`, String(item.isKM));
                formData.append(`${keyPrefix}isMile`, String(item.isMile));
                formData.append(`${keyPrefix}paymentMethod.id`, String(item.paymentMethod.id));
                formData.append(`${keyPrefix}expItemLocation.id`, String(item?.expItemLocation?.id));
                formData.append(`${keyPrefix}tripType.id`, String(item.tripType.id));
                formData.append(`${keyPrefix}itemDetail`, item.itemDetail);
                formData.append(`${keyPrefix}file`, item.file);
                formData.append(`${keyPrefix}attachmentFile`, item.attachmentFile);
                formData.append(`${keyPrefix}startLocation`, item.startLocation);
                formData.append(`${keyPrefix}endLocation`, item.endLocation);
                formData.append(`${keyPrefix}startLatLngs.lat`, item?.startLatLngs?.lat);
                formData.append(`${keyPrefix}startLatLngs.lng`, item?.startLatLngs?.lng);
                formData.append(`${keyPrefix}endLatLngs.lat`, item?.endLatLngs?.lat);
                formData.append(`${keyPrefix}endLatLngs.lng`, item?.endLatLngs?.lng);
                formData.append(`${keyPrefix}invoiceDataDraftId`, String(item.invoiceDataDraftId));
                formData.append(`${keyPrefix}isSplitLineItem`, String(item.isSplitLineItem));

                if (item.isSplitLineItem) {
                    item.splitLineItems.forEach((splitItem, splitIndex) => {
                        const splitKeyPrefix = `${keyPrefix}splitLineItems[${splitIndex}].`;
                        formData.append(`${splitKeyPrefix}splitLineItemId`, String(splitItem.splitLineItemId));
                        formData.append(`${splitKeyPrefix}expItemLocation.id`, String(splitItem.expItemLocation.id));
                        formData.append(`${splitKeyPrefix}amount`, String(splitItem.amount));
                        formData.append(`${splitKeyPrefix}percentage`, splitItem.percentage ? String(splitItem.percentage) : '');
                    });
                }

            });
            addExpense(formData);
            // console.log(values);

            // resetForm()
        },
    })

    const { resetForm } = formik;
    // useEffect(() => {
    //     if (isSuccess) {
    //         toast.success(tosterKey.expenseAddSuccessfully);
    //         resetForm()
    //         navigate("/expense-item");

    //     }
    // }, [isSuccess]);
    useEffect(() => {
        if (isSuccess) {
            const responseData = (addExpenseData as any)?.message;
            const successMessage = responseData || tosterKey.expenseAddSuccessfully;
            toast.success(successMessage);
            resetForm();
            navigate(allRoutes.expenseReports);
        }
    }, [isSuccess, addExpenseData]);






    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;

                // Check if the error message contains the specific text
                if (errorMessage.includes("There is no Workflow to Assign your expense but expense Save as draft")) {
                    navigate(allRoutes.expenseReports); // Redirect to "/expense-item"
                }
            }

            toast.error(errorMessage);
        }
    }, [isError, error]);

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
    }
    useEffect(() => {
        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef])

    useEffect(() => {
        let isValid = true;
        for (const item of formik.values.expenseItem) {
            if (!item?.expenseCategory?.id ||
                item.expenseCategory.id !== constraintConfig.categoriesId.mileage && orgDetail?.result?.receiptReqMinAmnt < item?.amount && !item?.attachmentFile ||
                !item?.itemDate ||
                !item?.currency?.id ||
                item?.expenseCategory?.id !== 14 && !item?.amount ||
                item?.expenseCategory?.id !== 14 && orgDetail?.result?.expPolicyMaxLimit < item?.amount ||
                item?.expenseCategory?.id === 14 && !item?.milage ||
                item?.expenseCategory?.id === 14 && !item?.tripType?.id ||
                item?.expenseCategory?.id !== 14 && !item?.vendor?.vendorId ||
                item?.expenseCategory?.id !== 14 && !item?.expItemLocation?.id ||
                !item?.paymentMethod?.id) {
                isValid = false;
                break;
            }
        }
        setIsStep2Valid(isValid);
    }, [formik.values.expenseItem]);
    const nextStep = () => {
        if (!stepper.current) {
            return;
        }

        if (stepper.current.currentStepIndex === 1) {
            // Check if Step 1 fields are valid
            if (!formik.values?.expenseType.id) {
                toast.error(tosterKey.expenseTypeIsRequired);
                return;
            } else if (!formik.values?.title || formik.values.title.trim() === "") {
                toast.error(tosterKey.reportNameIsRequired);
                return;
            } else if (formik.values.title.trim().length > 80) {
                toast.error(tosterKey.reportNameNotExceed80Characters);
                return;
            } else if (formik.values.title.length > 80) {
                toast.error(tosterKey.reportShouldNotExceed80Characters);
                return;
            } else if (!formik.values?.reportDate) {
                toast.error(tosterKey.enterAValidReportingPeriod);
                return;
            }
            else if (formik.values.expenseDetail?.trim().length > 500) {
                toast.error(tosterKey.purposeOfExpenseMustNotExceed500Characters);
                return;
            }
        }
        else if (stepper.current.currentStepIndex === 2) {
            // Extract remarks with index
            const remarksListing = Object.entries(uploadReceiptStep2).map(([rowId, rowData]) => ({
                rowId: parseInt(rowId), // Ensure rowId is a number
                remarks: rowData.remarks
            }));

            // Iterate over each expense item
            for (let index = 0; index < formik.values.expenseItem.length; index++) {
                const item = formik.values.expenseItem[index];
                // Find the corresponding remarks based on the index
                const remarks = remarksListing.find(r => r.rowId === index)?.remarks;
                if (remarks === "bank lineitem") {
                    toast.error('It is a bank receipt. Please add another receipt for Item ' + (index + 1));
                    return;
                }
                if (!item?.expenseCategory?.id) {
                    toast.error(tosterKey.categoryIsRequired + ` for Item ${index + 1}`);
                    return;
                } else if (item.expenseCategory.id !== constraintConfig.categoriesId.mileage && orgDetail?.result?.receiptReqMinAmnt < item?.amount && !item?.attachmentFile) {
                    toast.error(tosterKey.recieptIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (!item?.itemDate) {
                    toast.error(tosterKey.dateIsRequired + ` for Item ${index + 1}`);
                    return;
                } else if (!item?.currency?.id) {
                    toast.error(tosterKey.currencyIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (item?.expenseCategory?.id !== 14 && !item?.amount) {
                    toast.error(tosterKey.amountIsRequired + ` for Item ${index + 1}`);
                    return;
                } else if (item?.expenseCategory?.id !== 14 && orgDetail?.result?.expPolicyMaxLimit < item?.amount) {
                    toast.error(tosterKey.AmountExceedAccordingToExpensePolicyLimit + ` for Item ${index + 1}`);
                    return;
                } else if (item?.expenseCategory?.id === 14 && !item?.tripType?.id) {
                    toast.error(tosterKey.tripTypeIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (item?.expenseCategory?.id === 14 && !item?.milage) {
                    toast.error(tosterKey.startAndEndLocationIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (item?.expenseCategory?.id !== 14 && !item?.vendor?.vendorId) {
                    toast.error(tosterKey.vendorIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (item?.expenseCategory?.id !== 14 && !item?.expItemLocation?.id) {
                    toast.error(tosterKey.locationIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (!item?.paymentMethod?.id) {
                    toast.error(tosterKey.paymentMethodIsRequired + ` for Item ${index + 1}`);
                    return;
                }
                else if (item.itemDetail?.trim().length > 500) {
                    toast.error(tosterKey.expenseItemDetailMustNotExceed500Characters + ` for Item ${index + 1}`);
                    return;
                }

                // New validation to check incomplete split line items even when isSplitLineItem is false
                if (item.splitLineItems.length > 0) {
                    for (let i = 0; i < item.splitLineItems.length; i++) {
                        const splitItem = item.splitLineItems[i];

                        if (splitItem.amount && !splitItem.expItemLocation?.id && item.isSplitLineItem) {
                            toast.error(`Location is required for split line item ${i + 1} in Item ${index + 1}`);
                            return;
                        }
                    }
                }

                // Validate required fields within splitLineItem when isSplitLineItem is true
                if (item.isSplitLineItem) {
                    for (let i = 0; i < item.splitLineItems.length; i++) {
                        const splitItem = item.splitLineItems[i];

                        if (!splitItem.expItemLocation?.id) {
                            toast.error(`Location is required for split line item ${i + 1} in Item ${index + 1}`);
                            return;
                        }
                        if (!splitItem.amount || Number(splitItem.amount) <= 0) {
                            toast.error(`Amount is required and must be greater than 0 for split line item ${i + 1} in Item ${index + 1}`);
                            return;
                        }
                    }

                    // Check if split amount matches the total amount
                    const totalSplitAmount = item.splitLineItems?.reduce((sum, lineItem) => sum + (parseFloat(lineItem.amount) || 0), 0);
                    const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;
                    const totalAmount = Number(item.amount);
                    const remainingAmount = totalAmount - roundedTotalSplitAmount;
                    const roundedRemainingAmount = Math.round(remainingAmount * 100) / 100;

                    if (roundedRemainingAmount !== 0) {
                        toast.error(`${tosterKey.splitAmountMustMatchTotalAmount} for Item ${index + 1}`);
                        return;
                    }
                }


            }
        }
        else if (stepper.current.currentStepIndex === 2) {
            // Check if all fields in expenseItem array are valid
            let isValid = true;
            formik.values.expenseItem.forEach((item) => {
                if (!item?.expenseCategory?.id ||
                    item.expenseCategory.id !== constraintConfig.categoriesId.mileage && orgDetail?.result?.receiptReqMinAmnt < item?.amount && !item?.attachmentFile ||
                    !item?.itemDate ||
                    !item?.currency?.id ||
                    item?.expenseCategory?.id !== 14 && !item?.amount ||
                    item?.expenseCategory?.id !== 14 && orgDetail?.result?.expPolicyMaxLimit < item?.amount ||
                    item?.expenseCategory?.id === 14 && !item?.tripType?.id ||
                    item?.expenseCategory?.id === 14 && !item?.milage ||
                    item?.expenseCategory?.id !== 14 && !item?.vendor?.vendorId ||
                    item?.expenseCategory?.id !== 14 && !item?.expItemLocation?.id ||
                    !item?.paymentMethod?.id) {
                    isValid = false;
                    return;
                }
            });

            setIsStep2Valid(isValid);

            if (!isValid) {
                // Show toaster if validation fails
                toast.error(tosterKey.pleaseFillInAllFieldsForEachExpenseItem);
                return;
            }
        }

        stepper.current.goNext();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' && stepper.current?.currentStepIndex !== 3) {  // assuming 4 steps, index of last step is 3
            event.preventDefault();
        }
    }

    return (
        <>

            <div className="container-fluid p-0">
                <div
                    ref={stepperRef}
                    className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                    id='kt_create_account_stepper'
                >
                    {/* begin::Aside*/}

                    <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-250px w-xxl-250px me-9'>
                        {/* begin::Wrapper*/}
                        <div className='card-body px-6 px-lg-5 px-xxl-5 py-8'>
                            {/* begin::Nav*/}
                            <div className='stepper-nav ps-lg-5'>
                                {/* begin::Step 1*/}
                                <div className='stepper-item current' data-kt-stepper-element='nav'>
                                    {/* begin::Wrapper*/}
                                    <div className='stepper-wrapper'>
                                        {/* begin::Icon*/}
                                        <div className='stepper-icon w-40px h-40px'>
                                            <i className='stepper-check fas fa-check'></i>
                                            <span className='stepper-number'>1</span>
                                        </div>
                                        {/* end::Icon*/}

                                        {/* begin::Label*/}
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{labelKey.expenseReport}</h3>

                                            <div className='stepper-desc'>{labelKey.addExpenseDetails}</div>
                                        </div>
                                        {/* end::Label*/}
                                    </div>
                                    {/* end::Wrapper*/}

                                    {/* begin::Line*/}
                                    <div className='stepper-line h-25px'></div>
                                    {/* end::Line*/}
                                </div>
                                {/* end::Step 1*/}

                                {/* begin::Step 2*/}
                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <div className='stepper-wrapper'>
                                        <div className='stepper-icon w-40px h-40px'>
                                            <i className='stepper-check fas fa-check'></i>
                                            <span className='stepper-number'>2</span>
                                        </div>
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{labelKey.expenseItems}</h3>

                                            <div className='stepper-desc'>{labelKey.addExpenseItems}</div>
                                        </div>
                                    </div>
                                    <div className='stepper-line h-25px'></div>
                                </div>
                                {/* begin::Step 5*/}
                                <div className='stepper-item' data-kt-stepper-element='nav'>
                                    <div className='stepper-wrapper'>
                                        <div className='stepper-icon w-40px h-40px'>
                                            <i className='stepper-check fas fa-check'></i>
                                            <span className='stepper-number'>3</span>
                                        </div>

                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{labelKey.overview}</h3>

                                            <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end::Nav*/}
                        </div>
                        {/* end::Wrapper*/}
                    </div>
                    {/* begin::Aside*/}
                    {/* <div className='w-100'> */}
                    <div className='d-flex flex-row-fluid flex-center bg-body rounded py-2  px-6 px-lg-5 px-xxl-5 py-8'>
                        <form className='w-100 position-relative'
                            noValidate id='kt_create_account_form'
                            onSubmit={formik.handleSubmit}
                            onKeyDown={handleKeyDown}>
                            <Step1 formik={formik} />
                            <Step2 formik={formik} isStep2Valid={isStep2Valid} orgDetail={orgDetail} />
                            <Step5 formik={formik} />
                            <div className='d-flex flex-stack pt-5'>
                                <div className='mr-2'>
                                    <button
                                        onClick={prevStep}
                                        type='button'
                                        className='btn btn-lg btn-light-primary me-3'
                                        data-kt-stepper-action='previous'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr063.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {labelKey.back}
                                    </button>
                                </div>
                                <div>
                                    {!isLoading ?
                                        <>
                                            <button
                                                type='submit'
                                                className='btn btn-lg btn-primary me-3'
                                                data-kt-stepper-action='submit'
                                                disabled={isLoading}
                                                onClick={() => {
                                                    formik.setFieldValue('expenseStatus.id', 1);
                                                }}
                                            >
                                                <span className='indicator-label'>{labelKey.saveAsDraft}</span>
                                            </button>
                                            <button
                                                className='btn btn-lg btn-primary'
                                                data-kt-stepper-action='submit'
                                                onClick={() => {
                                                    formik.setFieldValue('expenseStatus.id', 2);
                                                }}
                                                disabled={isLoading}
                                            >
                                                <span className='indicator-label'>{labelKey.submitForApproval}</span>
                                            </button>
                                        </> :
                                        <button className='btn btn-lg btn-primary' disabled={isLoading}>
                                            {isLoading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    {labelKey.pleaseWait}{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    }
                                    <button
                                        type='button'
                                        className='btn btn-lg btn-primary'
                                        data-kt-stepper-action='next'
                                        onClick={nextStep}
                                    >
                                        {labelKey.nextStep}{' '}
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-1 me-0'
                                        />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default ExpenseSubmission