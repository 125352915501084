import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import labelKey from '../../../localization/label.json'
import AutomationCCFeedsStatsEnhancements from './AutomationCCFeedsStatsEnhancements'
type Props = {
    show: boolean
    handleClose: () => void
    bankFeedData?: any
    userBankFeedData?: any
}
const BankCCFeedDetailViewModal = ({ show, handleClose, bankFeedData, userBankFeedData }: Props) => {
    return (
        <Modal
            aria-hidden='true'
            dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
            show={show}
            onHide={handleClose}
        >

            <div className='modal-header py-4'>
                <h2 className='mb-0'>CC Bank Feed Detail ({bankFeedData?.fileName ? bankFeedData?.fileName : userBankFeedData?.accountNumber}) </h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body p-4'>
                <AutomationCCFeedsStatsEnhancements bankFeedData={bankFeedData} userBankFeedData={userBankFeedData} />
            </div>
            <div className='modal-footer py-4'>
                <button className='btn btn-light' onClick={handleClose}>{labelKey.close}</button>
            </div>
        </Modal >
    )
}

export default BankCCFeedDetailViewModal