import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/Expense${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const ExpenseApi = createApi({
    reducerPath: 'ExpenseApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + `/api/Expense/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    tagTypes: ['Expenses'],
    endpoints: (builder) => ({
        createExpenseWithItem: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.createExpense,
                    method: "POST",
                    body,
                }
            },
        }),
        getExpenseByUserId: builder.query({
            query: ({ userAccountId, pageNumber, pageSize, sortBy, sortOrder, searchStr, filterByExpType, startDate, endDate, filterByStatus }) => {
                return {
                    url: `${API_END_POINTS.expenseByUser}?userAccountId=${userAccountId}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByExpType=${filterByExpType}&startDate=${startDate}&endDate=${endDate}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),
        getExpenseItemByExpenseId: builder.query({
            query: ({ ExpenseId }) => {
                return {
                    url: `${API_END_POINTS.expenseItemById}?ExpenseId=${ExpenseId}`,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),
        updateExpenseWithItems: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateExpenseWithItem,
                    method: "PUT",
                    body,
                }
            },
        }),
        deleteExpenseItem: builder.mutation({
            query: (ExpenseItemId) => {
                return {
                    url: `${API_END_POINTS.deleteExpenseItem}?ExpenseItemId=${ExpenseItemId}`,
                    method: "DELETE",
                }
            },
        }),

        updateExpenseItems: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateExpenseItem,
                    method: "PUT",
                    body,
                }
            },
        }),

        updateExpenseStatus: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateExpenseStatus,
                    method: "PUT",
                    body,
                }
            },
        }),

        getExpenseOfManagerTeam: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.expensesOfManagerTeam}`,
                    method: "GET",
                    params
                }
            },
            providesTags: ['Expenses']
        }),
        getExpenseLog: builder.query({
            query: ({ expenseId }) => {
                return {
                    url: `${API_END_POINTS.expenseLog}?expenseId=${expenseId}`,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),

        calculateDistance: builder.mutation({
            query: () => {
                return {
                    url: API_END_POINTS.calculateDistance,
                    method: "POST",
                }
            },
        }),
        getMileage: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getMileage,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),
        addUpdateMileage: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addUpdateMileage,
                    method: "POST",
                    body
                }
            },
        }),
        getCardExpenseItem: builder.query({
            query: ({ userAccountId, pageSize, pageNumber, sortBy, sortOrder, searchStr, filterByCategory, startDate, endDate, filterBySubCategory }) => {
                return {
                    url: `${API_END_POINTS.getCardExpenseItems}?userAccountId=${userAccountId}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByCategory=${filterByCategory}&startDate=${startDate}&endDate=${endDate}&filterBySubCategory=${filterBySubCategory}`,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),
        addUpdateCardExpenseItem: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addUpdateExpenseItem,
                    method: "POST",
                    body
                }
            },
        }),
        assigntoNewExpense: builder.mutation({
            query: (body) => ({
                url: API_END_POINTS.assignItemToNewExpense,
                method: 'PUT',
                body,
            }),
        }),
        assigntoExistingExpense: builder.mutation({
            query: (body) => ({
                url: API_END_POINTS.assignItemToExistingExpense,
                method: 'PUT',
                body,
            }),
        }),
        addItemToExpense: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseToAddItems}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['Expenses']
        }),
        getExpenseForReport: builder.query({
            query: ({ expenseId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseForReport}?expenseId=${expenseId}`,
                    method: "GET",
                }
            },
        }),
        clarificationNote: builder.mutation({
            query: (body) => ({
                url: API_END_POINTS.addClarificationNote,
                method: 'PUT',
                body,
            }),
        }),
        getMileageByDate: builder.query({
            query: ({ date }) => {
                return {
                    url: `${API_END_POINTS.getMileagebyDate}?date=${date}`,
                    method: "GET",
                }
            },
        }),
        getExpenseForAp: builder.query({
            query: ({ accountPayableId, pageSize, pageNumber, sortBy, sortOrder, searchStr, filterByCategory, startDate,endDate, filterByStatus }) => {
                return {
                    url: `${API_END_POINTS.getExpensesForAP}?accountPayableId=${accountPayableId}&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByCategory=${filterByCategory}&startDate=${startDate}&endDate=${endDate}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        updateStatusByAp: builder.mutation({
            query: (body) => ({
                url: API_END_POINTS.updateExpenseStatusToAccountsPayable,
                method: 'PUT',
                body,
            }),
        }),
        updateExpenseToSpecificAP: builder.mutation({
            query: ({ expenseId, isActive }) => {
                return {
                    url: `${API_END_POINTS.updateExpenseToSpecificAP}?expenseId=${expenseId}&isActive=${isActive}`,
                    method: "PUT",
                }
            },
        }),
        // upsertExpenseViolation: builder.mutation({
        //     query: (body) => ({
        //         url: API_END_POINTS.upsertExpenseViolation,
        //         method: 'PUT',
        //         body,
        //     }),
        // }),
        getExpenseApproversByExpenseId: builder.query({
            query: ({ expenseId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseApproversByExpenseId}?expenseId=${expenseId}`,
                }
            },
        }),
        getExpenseItemsByExpenseItemId: builder.query({
            query: ({ expenseItemId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseItemsByExpenseItemId}?expenseItemId=${expenseItemId}`,
                }
            },
        }),
        recallExpenseReport: builder.mutation({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.recallExpenseReport}`,
                    method: "PATCH",
                    params
                }
            },
        }),
        reAssignExpenseToOtherManager: builder.mutation({
            query: (body) => {
                return {
                    url: `${API_END_POINTS.reAssignExpenseToOtherManager}`,
                    method: "PATCH",
                    body
                }
            },
        }),
        mergeLineItems: builder.mutation({
            query: (body) => {
                return {
                    url: `${API_END_POINTS.mergeLineItems}`,
                    method: "POST",
                    body
                }
            },
        }),
        getExpenselogDetail: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getExpenselogDetail}`,
                    method: "GET",
                    params
                }
            },
        }),
        updateExpenseStatusToPaid: builder.mutation({
            query: (body) => {
                return {
                    url: `${API_END_POINTS.updateExpenseStatusToPaid}`,
                    method: "PUT",
                    body
                }
            },
        }),
    }),
})

export const {
    useGetExpenseByUserIdQuery,
    useGetExpenseItemByExpenseIdQuery,
    useCreateExpenseWithItemMutation,
    useUpdateExpenseWithItemsMutation,
    useDeleteExpenseItemMutation,
    useUpdateExpenseItemsMutation,
    useUpdateExpenseStatusMutation,
    useGetExpenseOfManagerTeamQuery,
    useGetExpenseLogQuery,
    useCalculateDistanceMutation,
    useGetMileageQuery,
    useAddUpdateMileageMutation,
    useGetCardExpenseItemQuery,
    useAddUpdateCardExpenseItemMutation,
    useAssigntoNewExpenseMutation,
    useAssigntoExistingExpenseMutation,
    useAddItemToExpenseQuery,
    useGetExpenseForReportQuery,
    useLazyGetExpenseForReportQuery,
    useClarificationNoteMutation,
    useGetMileageByDateQuery,
    useGetExpenseForApQuery,
    useUpdateStatusByApMutation,
    useUpdateExpenseToSpecificAPMutation,
    useGetExpenseApproversByExpenseIdQuery,
    useGetExpenseItemsByExpenseItemIdQuery,
    useLazyGetExpenseItemsByExpenseItemIdQuery,
    useRecallExpenseReportMutation,
    useLazyGetExpenseItemByExpenseIdQuery,
    useMergeLineItemsMutation,
    useReAssignExpenseToOtherManagerMutation,
    useGetExpenselogDetailQuery,
    useUpdateExpenseStatusToPaidMutation
    // useUpsertExpenseViolationMutation,
} = ExpenseApi